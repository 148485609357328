import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

interface LoadingBarProps {
  progress: number;
  transition?: string;
  onMount?: () => void;
}

const Container = styled.div`
  width: 100%;
  height: 0.625rem;
  max-width: 16.25rem;
  background-color: ${({ theme }) => theme.colors.cultured};
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 0.0625rem;
`;

const Progress = styled.div<{ width: number; transition: string }>`
  width: ${({ width }) => (width > 100 ? 100 : width)}%;
  transition: ${({ transition }) => transition};
  background-color: ${({ theme }) => theme.colors.green};
  height: 0.5rem;
  border-radius: 0.25rem;
`;

const LoadingBar: FC<LoadingBarProps> = ({
  progress,
  transition = '5s ease',
  onMount,
}) => {
  useEffect(() => {
    onMount?.();
  }, []);

  return (
    <Container>
      <Progress width={progress} transition={transition} />
    </Container>
  );
};

export default LoadingBar;
