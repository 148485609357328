import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { LoadingPage, PageContainer, Seo, Text } from 'components';
import Header from 'components/Header';
import { useRouter } from 'apis/history';
import { useQuery } from 'styles/breakpoints';
import { usePageView, useQuizData } from 'utils/hooks';
import LoadingBar from './components/LoadingBar';
import Counter from './components/Counter';

const counterStyle = `
  height: 1.0625rem;
  margin-top: 0.5rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 100%;
`;

const Title = styled(Text).attrs({
  color: 'dark100',
})`
  text-align: center;
  margin-bottom: 1rem;
`;

const CounterStyled = styled(Counter)`
  ${counterStyle}
`;

const CounterPlaceholder = styled.div`
  ${counterStyle}
`;

const DURATION = 3;

const OnboardingCalculating: FC = () => {
  const [progress, setProgress] = useState<number>(0);

  const data = useQuizData('calculating');

  const { goToOnBoardingEmail } = useRouter();

  const { isMobile } = useQuery();

  usePageView();

  const handleNavigationOnCounterEnd = () => {
    goToOnBoardingEmail();
  };

  const handleOnLoad = () => {
    setTimeout(() => {
      setProgress(100);
    }, 1500);
  };

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <PageContainer with100vh>
        <Header />
        <ContentContainer>
          <Title type={'h2'}>Please wait while we check for availability</Title>
          <LoadingBar
            progress={progress}
            transition={`${DURATION}s linear`}
            onMount={handleOnLoad}
          />
          {progress ? (
            <CounterStyled
              onEnd={handleNavigationOnCounterEnd}
              duration={DURATION}
              type="body700"
            />
          ) : (
            <CounterPlaceholder />
          )}
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default OnboardingCalculating;
